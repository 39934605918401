<template>
  <section class="main-section storage-more-section">
    <div class="storage-more-title">柜体架构以及品质</div>
    <div class="storage-more-desc">
      <p>柜体板19mm厚度，配以3mm后背板，整个柜 体采⽤榫卯注胶⼯艺，有效防⽌层板晃动或者 倾斜，⼤⼤增加柜体牢固度。</p>
    </div>
    <div class="storage-img font-0">
      <img :src="ossPath + 'storage-5.png'" class="full-img" />
    </div>
    <div class="storage-bottom font-0">
      <img :src="ossPath + 'storage-bg-bottom.png'" class="full-img" />
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/'
  }),
  created () {
    this.$store.commit('updateActiveNav', 1)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/space.css';
</style>
